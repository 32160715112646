import {IEnum} from "./enum";
import {IFile, IFileLine, IFilePagined, IFileRender} from './file';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IBusinessPartner} from "./business-partner";
import {IStage} from "./stage";
import {IDataCollectorFile, IItem} from "./item";
import {ITaxCode} from "./tax-code";
import {IUserByCompany, IUserDto} from "./user";
import {IRole} from "./role";
import {IUsersCreatedBy} from "./user-company";


/**
 * Represents data resolver for file consulting providing various options and responses.
 */
export interface IFileConsultingDataResolver {
  /**
   * An array of file status options represented as `IEnum`.
   * This property may be null if not available.
   */
  FileStatusOptions: IEnum[] | null;

  /**
   * An `ICLResponse` containing an array of files represented as `IFile`.
   * This property may be null if no files are available or if there's an issue with the response.
   */
  Files: ICLResponse<IFilePagined[]> | null;

  /**
   * An array of business units represented as `IEnum`.
   * This property may be null if not available.
   */
  BusinessUnits: IEnum[] | null;

  /**
   * An array of stages represented as `IStage`.
   * This property may be null if not available.
   */
  Stages: ICLResponse<IStage[]> | null;


  /**
   * An array of stages represented as `IBusinessPartner`.
   * This property may be null if not available.
   */
  BussinesPatners: ICLResponse<IBusinessPartner[]> | null;

  /**
   * List of status, countries, incoterms or warehouse as an array of `IDataCollectorFile` objects.
   * Can be null if no stages are available.
   */
  DataCollectorFile: ICLResponse<IDataCollectorFile[]> | null;
}


/**
 * Represents data resolver for "My Files" providing various options and responses.
 */
export interface IMyFilesDataResolver {
  /**
   * An array of file status options represented as `IEnum`.
   * This property may be null if not available.
   */
  FileStatusOptions: IEnum[] | null;

  /**
   * An `ICLResponse` containing an array of files represented as `IFile`.
   * This property may be null if no files are available or if there's an issue with the response.
   */
  Files: ICLResponse<IFilePagined[]> | null;

  /**
   * An array of business units represented as `IEnum`.
   * This property may be null if not available.
   */
  BusinessUnits: IEnum[] | null;

  /**
   * An array of stages represented as `IStage`.
   * This property may be null if not available.
   */
  Stages: ICLResponse<IStage[]> | null;

  /**
   * An array of stages represented as `IBusinessPartner`.
   * This property may be null if not available.
   */
  BussinesPatners: ICLResponse<IBusinessPartner[]> | null;

  /**
   * List of status, countries, incoterms or warehouse as an array of `IDataCollectorFile` objects.
   * Can be null if no stages are available.
   */
  DataCollectorFile: ICLResponse<IDataCollectorFile[]> | null;
}


/**
 * Data resolver for file-related information.
 */
export interface IFileDataResolver {
  /**
   * List of business partners as an `ICLResponse` containing `IBusinessPartner` objects.
   * Can be null if no business partners are available or if there's an issue with the response.
   */
  BusinessPartners: ICLResponse<IBusinessPartner[]> | null;

  /**
   * List of business partners as an `ICLResponse` containing `IBusinessPartner` objects.
   * Can be null if no business partners are available or if there's an issue with the response.
   */
  BusinessPartnersSupplier: ICLResponse<IBusinessPartner[]> | null;


  /**
   * List of business unit as an array of `IEnum` objects.
   * Can be null if no business unit are available.
   */
  BusinessUnit: IEnum[] | null;

  /**
   * List of stages as an array of `IStage` objects.
   * Can be null if no stages are available.
   */
  Stages: ICLResponse<IStage[]> | null;

  /**
   * List of status, countries, incoterms or warehouse as an array of `IDataCollectorFile` objects.
   * Can be null if no stages are available.
   */
  DataCollectorFile: ICLResponse<IDataCollectorFile[]> | null;

  /**
   * List of items of SAP as an array of `IItem` objects.
   * Can be null if no items are available.
   */
  Items: ICLResponse<IItem[]> | null;

  /**
   * List of tax codes as an array of `ITaxCode` objects.
   * Can be null if no items are available.
   */
  TaxCodes: ICLResponse<ITaxCode[]> | null;

  /**
   * List of business unit as an array of `IEnum` objects.
   * Can be null if no business unit are available.
   */
  ColorsCapacity: IEnum[] | null;
}




/**
 * Data resolver for file-related information.
 */
export interface IPaymentRequestConsultingDataResolver {
  /**
   * List of business unit as an array of `IEnum` objects.
   * Can be null if no business unit are available.
   */
  BusinessUnit: IEnum[] | null;

  /**
   * An array of stages represented as `IStage`.
   * This property may be null if not available.
   */
  Stages: ICLResponse<IStage[]> | null;

  /**
   * An `ICLResponse` containing an array of files represented as `IFile`.
   * This property may be null if no files are available or if there's an issue with the response.
   */
  Currencies: IEnum[] | null;
  /**
   * An `ICLResponse` containing an array of files represented as `IFileRender`.
   * This property may be null if no files are available or if there's an issue with the response.
   */
  Files: ICLResponse<IFileRender[]> | null;
}


/**
 * Represents data resolver for "My Files" providing various options and responses.
 */
export interface IHistoryDataResolver {


  /**
   * An array of business units represented as `IEnum`.
   * This property may be null if not available.
   */
  BusinessUnits: IEnum[] | null;


}


/**
 * Represents data resolver for "My Files" providing various options and responses.
 */
export interface IUsersDataResolver {


  /**
   * An array of roles represented as `IEnum`.
   * This property may be null if not available.
   */
  Roles:  ICLResponse<IRole[]>| null;

  /**
   * An array of roles represented as `IEnum`.
   * This property may be null if not available.
   */
  Users: ICLResponse<IUserDto[]> | null;


}

/**
 * Data resolver for file-related information.
 */
export interface IPaymentRequestInfoResolver {

  /**
   * List of created by users as an `ICLResponse` containing `IUsersCreatedBy` objects.
   * Can be null if no created users are available or if there's an issue with the response.
   */
  CreatedByUsers: ICLResponse<IUsersCreatedBy[]> | null;

  /**
   * List of business partners as an `ICLResponse` containing `IBusinessPartner` objects.
   * Can be null if no business partners are available or if there's an issue with the response.
   */
  BusinessPartnersSupplier: ICLResponse<IBusinessPartner[]> | null;

  /**
   * An `ICLResponse` containing an array of files represented as `IFile`.
   * This property may be null if no files are available or if there's an issue with the response.
   */
  Currencies: IEnum[] | null;

  /**
   * An array of user represented as `IEnum`.
   * This property may be null if not available.
   */
  CurrentUser: ICLResponse<IUserDto> | null

}
