import { Injectable } from '@angular/core';
import {Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {finalize, forkJoin, map, Observable, of} from 'rxjs';
import {IBusinessPartnerFilter} from "../interfaces/business-partner";
import {AssetsService} from "../services/assets.service";
import {OverlayService} from "@clavisco/overlay";
import {IPaymentRequestInfoResolver} from "../interfaces/resolver-data-interfaces";
import {BusinessPartnerService} from "../services/business-partner.service";
import {IUsersCreatedByFilter} from "../interfaces/user-company";
import {UserService} from "../services/user.service";
import {DataStorageService} from "../services/data-storage.service";
import {ICompany} from "../interfaces/companys";

@Injectable({
  providedIn: 'root'
})
export class PaymentRequestInfoResolver implements Resolve<IPaymentRequestInfoResolver> {

  constructor(private assetService: AssetsService,
              private clBlockUI: OverlayService,
              private bpService: BusinessPartnerService,
              private userService: UserService,
              private dataStorageService: DataStorageService

  ) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPaymentRequestInfoResolver> {
    return this.RequestRequiredData();
  }
  RequestRequiredData(): Observable<IPaymentRequestInfoResolver> {

    const filterBP :IBusinessPartnerFilter = {BpType:'Supplier'};
    const company: ICompany =  <ICompany>this.dataStorageService.getSessionStorage('CompanyData');
    const filterCreatedBy :IUsersCreatedByFilter = {CompanyId: company.Id, IsActive: true };
    this.clBlockUI.OnGet('Obteniendo informacion requerida');


    return forkJoin([
      this.bpService.Get(filterBP),
      this.userService.GetUsersCreatedByFilter(filterCreatedBy),
      this.assetService.GetCurrencies(),
      this.userService.GetUserLogged()
    ]).pipe(map(([businessPartners, CreatedByUsers, currencies, UserLogged  ]) => {
        return {
          BusinessPartnersSupplier: businessPartners,
          CreatedByUsers: CreatedByUsers,
          Currencies: currencies,
          CurrentUser: UserLogged
        } as IPaymentRequestInfoResolver
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    )
  }
}
