export const environment = {
  production: false,
  env: 'Desarrollo',
  //ApiUrl: "http://localhost:56671/",
  ApiUrl: "https://fafilapidev.clavisco.com/",
  recatpchaSiteKey: '6Lei-OwpAAAAAA8NGpqlaJfM-uduGXM2M0sI1WS6',
  HomeTitle:'Fenix Aduanal - Development environment',
  hiddenModules: [''],
  LogRocketId: ''
};

