import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {finalize, forkJoin, map, Observable, of} from 'rxjs';
import {IFileConsultingDataResolver, IMyFilesDataResolver} from "../interfaces/resolver-data-interfaces";
import {AssetsService} from "../services/assets.service";
import {FileService} from "../services/file.service";
import {OverlayService} from "@clavisco/overlay";
import {StageService} from "../services/stage.service";
import {BusinessPartnerService} from "../services/business-partner.service";
import {IBusinessPartnerFilter} from "../interfaces/business-partner";
import {CommonService} from "../services/common.service";
import {CompanyService} from "../services/company.service";
import {AppService} from "../services/app.service";

@Injectable({
  providedIn: 'root'
})
export class MyFilesResolver implements Resolve<IMyFilesDataResolver> {
  filterBP :IBusinessPartnerFilter = {BpType:'Customer'};
  today: Date;
  lastWeek: Date;
  constructor(private assetService: AssetsService,
              private fileService: FileService,
              private clBlockUI: OverlayService,
              private stageService: StageService,
              private bpService: BusinessPartnerService,
              private commonService: CommonService,
              private appService: AppService,
              private companyService: CompanyService
              ) {
    this.today = new Date();
    this.lastWeek = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7);

   
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IMyFilesDataResolver> {
    return this.RequestRequiredData();
  }
  RequestRequiredData(): Observable<IMyFilesDataResolver> {

   
    this.clBlockUI.OnGet('Obteniendo informacion requerida');



    return forkJoin([
      this.assetService.GetFileStatusOptions(),
      this.assetService.GetBusinessUnit(),
      this.stageService.Get(),
      this.bpService.Get(this.filterBP),
      this.fileService.GetDataCollectorFile(),
      this.fileService.Filter({
        StartDate: this.commonService.FormatDate( this.lastWeek.toString()),
        EndDate: this.commonService.FormatDate( this.today.toString()),
        StartPos: 0,
        RowsReturned: 10
      }),
    ]).pipe(map(([fileStatusOptions, businessUnits, stages,
                              bussinesPatners, dataFile, files]) => {
        return {
            FileStatusOptions: fileStatusOptions,
            BusinessUnits:businessUnits,
            Stages: stages,
            BussinesPatners: bussinesPatners,
            DataCollectorFile: dataFile,
            Files: files
        } as IMyFilesDataResolver
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    )
  }
}
