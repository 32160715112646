import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map, Observable, tap, throwError} from 'rxjs';
import {Repository} from '@clavisco/core';
import {AuthenticationService} from '../services/authentication.service';
import {MatDialog} from "@angular/material/dialog";
import {CLModalType, ModalService} from "@clavisco/alerts";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  constructor(
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private modalService: ModalService
  ) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('assets') || req.url.includes('token') || req.url.includes('ChangeRecoverPassword') || req.url.includes('RecoveryPassword') || req.url.includes('ChangePassword')) {

      return next.handle(req).pipe(
        catchError((error: any) => {
          return throwError(() => {

            if (error?.error?.code === HttpStatusCode.Unauthorized) {
              return error = error.error.message;
            }
            return error;
          });
        }));
    }

    let clonedRequest: HttpRequest<unknown> = this.SetTokenToRequest(req.clone());

    return next.handle(clonedRequest);
  }


  private SetTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {

    const TOKEN = Repository.Behavior.GetStorageObject<any>('CurrentSession');


    if (!TOKEN) {
      return request;
    }

    if (!this.authService.isAuthenticated()) {
      this.dialog.closeAll();
      this.authService.logout();
      this.modalService.Continue({
        title: 'Su sesión ha expirado o no está autenticado',
        subtitle: 'Por favor, inicie sesión nuevamente para continuar.',
        type: CLModalType.ERROR,
        disableClose:true
      }).subscribe();
      throw new Error('No está autenticado, por favor inicie sesión');
    }

    let headers = request.headers.set(`Authorization`, `Bearer ${TOKEN.access_token}`);

    request = request.clone({headers});
    return request;

  }


}
