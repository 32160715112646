import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {catchError, finalize, Subscription} from "rxjs";
import {Repository, Structures} from "@clavisco/core";
import {CompanyInitialData, ICompany} from "../../interfaces/companys";
import {CompanyService} from "../../services/company.service";
import {OverlayService} from "@clavisco/overlay";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {MatDialogRef} from "@angular/material/dialog";
import {AuthenticationService} from "../../services/authentication.service";
import {IUserByCompany} from "../../interfaces/user";


@Component({
  selector: 'app-select-company-modal',
  templateUrl: './select-company-modal.component.html',
  styleUrls: ['./select-company-modal.component.scss']
})
export class SelectCompanyModalComponent implements OnInit, OnDestroy {

  allSuscription: Subscription = new Subscription();
  companiesList: IUserByCompany[] = [];

  tableId: string = 'CompanyTable';
  displayedColumns!: MappedColumns;
  scrollHeight: string = '300px';
  pageSizeOptions: number[] = [5, 10, 15];
  itemsPeerPage: number = 5;
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  currentPage: number = 1;

  Buttons: any = [
    {
      Title: `Seleccionar Compañía`,
      Action: Structures.Enums.CL_ACTIONS.CONTINUE,
      Icon: `arrow_forward`,
      Color: `primary`
    },
  ];

  constructor(
    @Inject('LinkerService') private linkerService: LinkerService,
    private companyService: CompanyService,
    private clBlockUI: OverlayService,
    private alertService: AlertsService,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<SelectCompanyModalComponent>,
  ) {


    Register<CL_CHANNEL>(this.tableId, CL_CHANNEL.OUTPUT, this.ButtonsEvent, this.callbacks);
    this.allSuscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => this.alertService.Toast({message: error, type: CLToastType.ERROR})
      }));
    this.displayedColumns = MapDisplayColumns({
      dataSource: [],
      renameColumns: {
        CompanyName: 'Nombre de la compañía',
        DatabaseCode: 'Base de datos',
      },
      ignoreColumns: ['Id','CreatedDate', 'CreatedBy', 'UpdateDate', 'UpdatedBy', 'IsActive', 'Profit', 	'ConnectionId',	'Connection', "CompanyId", "UserId", "User", "Company"],
      tooltipsPosition: {rows: 'left', cells: 'right'}


    });
  }

  ButtonsEvent = (_event: ICLEvent): void => {
    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT: IUserByCompany = JSON.parse(BUTTON_EVENT.Data);


      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.CONTINUE:
          const initialData: CompanyInitialData = {CompanyName: ELEMENT.CompanyName, Id:ELEMENT.CompanyId} as CompanyInitialData;
         this.authService.SetCompanySelected(initialData);
          this.dialogRef.close(true);
          break;
      }
    }
  };
  ngOnInit(): void {
    this.GetAssignedCompanies();
  }

  LoadTableData(): void {
    const CURRENT_TABLE_STATE = {
      CurrentPage: this.currentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.companiesList,
      RecordsCount: this.recordsCount
    };
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableId,
      Data: JSON.stringify(CURRENT_TABLE_STATE)
    } as ICLEvent);
  }

  ngOnDestroy(): void {
    this.allSuscription.unsubscribe();
  }
  /*
 * Method to gets the assigned companies
  */
  GetAssignedCompanies(): void {
    this.clBlockUI.OnGet();
    this.companyService.GetAssignedCompanies().pipe(finalize(() => {
        this.clBlockUI.Drop();
      }),
      catchError((err) => {
        return [];
      })).subscribe((data) => {

      if (data.Data) {
        this.companiesList = data.Data;
        this.recordsCount = this.companiesList.length;
        this.LoadTableData();

      } else {
        this.alertService.Toast({message: 'No se pudo obtener las compañías asignadas a su usuario'});
      }

    });
  }

  /*
    * Method to logout
  */
  Logout(): void {
    this.dialogRef.close(false);
    this.authService.logout();
  }

}
