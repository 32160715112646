import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IStage} from "../interfaces/stage";
import {CommonService} from "./common.service";

/*
  Service responsible for handling stage-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class StageService {

  private readonly CONTROLLER = 'api/Stage'

  constructor(
    private http: HttpClient, private commonService: CommonService
  ) {
  }

  Get() : Observable<ICLResponse<IStage[]>>{
    return this.http.get<ICLResponse<IStage[]>>(`${this.CONTROLLER}`, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener etapas`
      })});
  }

}
