import {Injectable} from "@angular/core";
import {CLPrint, Repository, Structures} from '@clavisco/core';
import {Router} from "@angular/router";
import {IToken} from "../interfaces/token";
import {IUserDto} from "../interfaces/user";
import {CompanyInitialData} from "../interfaces/companys";
import {DataStorageService} from "./data-storage.service";
import {ICurrency} from "../interfaces/currency";
import {interval, Subject, Subscription} from "rxjs";
import {CLModalType, ModalService} from "@clavisco/alerts";

/*
  Service responsible for handling authentication-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //private loginSubject = new BehaviorSubject<boolean>(false);
  //authStatus$ = this.loginSubject.asObservable();
  public currentToken: Subject<Date>;
  private subscription!: Subscription;
  private tokenExpire: Date | null = null;

  constructor(
    private router: Router,
    private dataService: DataStorageService,
    private modalService: ModalService
  ) {
    // if (this.GetCurrentUser()) {
    //   this.loginSubject.next(this.HaveCompanySelected());
    // }
    this.currentToken = new Subject<Date>();
  }

  /**
   * Starts a timer that runs to validate the user's session.
   * If a previous timer is running, it stops it before starting a new one.
   */
  StartTimer(): void{
    this.StopTimer();
    this.subscription = interval(1900).subscribe(() => {
      this.ValidateSession();
    });
  }

  /**
   * Stops the session validation timer if it is currently running.
   */
  StopTimer(): void{
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Validates the user's session by checking if the authentication token has expired.
   * If the token is expired, it displays a notification and logs the user out.
   */
  ValidateSession(): void{
    if(this.tokenExpire){
      const today: Date = new Date();
      const currentUTCDate: Date = new Date(today.getTime() + today.getTimezoneOffset() * 60000);
      if (currentUTCDate > this.tokenExpire) {
        this.logout();
        this.modalService.Continue({
          title: 'Su sesión ha expirado o no está autenticado',
          subtitle: 'Por favor, inicie sesión nuevamente para continuar.',
          type: CLModalType.ERROR,
          disableClose:true
        }).subscribe();
        return;
      }
    }
  }

  /**
   * Retrieves the current user from session storage.
   *
   * @returns {IUserDto} - The current user object, or an empty object if no user is found in the session.
   */
  GetCurrentUser(): IUserDto {
    const TOKEN: IToken | null = Repository.Behavior.GetStorageObject<IToken | null>('CurrentSession');
    if (TOKEN && TOKEN.user) {
      const userObj = JSON.parse(TOKEN.user);
      Repository.Behavior.SetStorage<IUserDto>(userObj,"CurrentUser");
      return userObj;
    } else {
      return {} as IUserDto;
    }
  }

  /**
   * Checks if the user is authenticated by validating the session.
   * Retrieves session data from storage, checks the expiration time,
   * and determines if the session is still valid.
   *
   * @returns {boolean} - `true` if the session is valid (not expired), `false` otherwise.
   */
  public isAuthenticated(): boolean {
    const session = Repository.Behavior.GetStorageObject<any>('CurrentSession');
    if(session){
      const expires: Date = new Date(session.expires)
      this.tokenExpire = expires;
      this.currentToken.next(this.tokenExpire);
    }
    return session ? new Date(session.expires) >= this.GetUTCNow() : false;
  }

  /**
   * Retrieves the selected company from session storage.
   * Checks if company data exists in the session, and returns it if found.
   *
   * @returns {CompanyInitialData | null} - The selected company data, or `null` if no company is selected.
   */
  public GetCompanySelected(): CompanyInitialData{

    let selectedCompany = Repository.Behavior.GetSessionObject<CompanyInitialData>("CompanyData") as CompanyInitialData;

    return  selectedCompany ?? null;
  }

  /**
   * Checks if a company has been selected in the session.
   *
   * @returns {boolean} - `true` if a company is selected, `false` if no company is selected.
   */
  public HaveCompanySelected(): boolean {

      return this.GetCompanySelected() != null;
  }

  /**
   * Retrieves the current date and time in UTC format.
   * The returned date object is adjusted to reflect UTC hours, minutes, and seconds.
   *
   * @returns {Date} - The current UTC date and time.
   */
  private GetUTCNow(): Date {
    const dateNow: Date = new Date();
    return new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
  }

  /**
   * Logs the user out by clearing stored session data,
   * and stopping the session validation timer.
   */
  public logout(): void {
    try {
      Repository.Behavior.DeleteStorageObject('CurrentSession');
      Repository.Behavior.DeleteStorageObject('CompanyData');
      Repository.Behavior.DeleteStorageObject('Currencies');
      sessionStorage.clear();
      //this.loginSubject.next(false);
      this.router.navigateByUrl('/Login');
      this.StopTimer();
    } catch (error) {
      CLPrint(error, Structures.Enums.CL_DISPLAY.ERROR);
    }
  }

  public ValidateRol(role: string) : boolean{
    return this.GetCurrentUser().RoleName == role
  }

  public HavePermissionl(permission: string) : boolean{
   return  this.dataService.GetUserAccess().includes(permission);
  }
  public SetCompanySelected(company: CompanyInitialData){
    Repository.Behavior.SetSession<CompanyInitialData>(company, "CompanyData");
    //this.loginSubject.next(true);
  }

  public SetCurrencies(currencies : ICurrency[]){
    Repository.Behavior.SetSession<ICurrency[]>(currencies, "Currencies");
  }

  public GetCurrencies(){
    let currencies = Repository.Behavior.GetSessionObject<ICurrency[]>("Currencies") as ICurrency[];

    return currencies;
  }

}
