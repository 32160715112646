import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {IMenuNode} from "@clavisco/menu";
import {Router} from "@angular/router";
import {MenuService} from "../services/menu.service";
import {catchError, finalize, forkJoin, of, Subscription, tap} from "rxjs";
import {OverlayService} from "@clavisco/overlay";
import {environment} from "../../environments/environment";
import {AuthenticationService} from "../services/authentication.service";
import {IMenuItem} from "../interfaces/menu-node";
import {Repository, Structures} from "@clavisco/core";
import {UserService} from "../services/user.service";
import {IUser, IUserDto} from "../interfaces/user";
import {CompanyService} from "../services/company.service";
import {CompanyCurrency, CompanyInitialData} from "../interfaces/companys";
import {AlertsService} from "@clavisco/alerts";
import {AppService} from "../services/app.service";

import {MatDialog} from "@angular/material/dialog";
import {SelectCompanyModalComponent} from "./select-company-modal/select-company-modal.component";
import {AssetsService} from "../services/assets.service";
import {ICurrency} from "../interfaces/currency";
import {F} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})

export class PagesComponent implements OnInit , OnDestroy, AfterViewInit {
  allSuscription: Subscription = new Subscription();
  validateUrls: string[] = ['/CreateFile/Customs', '/CreateFile/International', '/File/Customs/', '/File/International/'];
  ShowMenu: boolean = true;

  routesWithoutMenu = ['PaymentRequestDetails'];

  MenuId: string = "main-menu";
  MenuNodes: IMenuNode[] = [];
  LogoDefault: string = "../../assets/img/imagotipo.png";
  LogoSrc: string = this.LogoDefault;
  currentUser!: IUserDto;
  Title: string = environment.HomeTitle;
  CurrentPageTitle: string = "";
  profileDefault: string = '../../assets/img/user.png';
  profile: string = this.profileDefault;
  Toggle: boolean = true;



  localCurrencies: ICurrency[] = [];

  companyInitialData: CompanyInitialData | null = null;

  companyCurrency: CompanyCurrency | null = null;

  userLogged: IUser | null = null;

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };

  constructor(
    @Inject('LinkerService') private linkerervice: LinkerService,
    private router: Router,
    private menuService: MenuService,
    private clBlockUI: OverlayService,
    private userService: UserService,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private alertService: AlertsService,
    private appService: AppService,
    private assetsServices: AssetsService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,

  ) {
    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);
    this.allSuscription.add(this.linkerervice.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => console.log(`Error CLAVIS:`, error)
    }));

    this.allSuscription.add(this.menuService.MenuItem.subscribe((next: IMenuItem[]) => {
      this.MenuNodes = next as IMenuNode[];
      const menu: IMenuNode[] = this.MenuNodes.filter(x => x.Visible);
      this.linkerervice.Publish(
        {
          CallBack: CL_CHANNEL.INFLATE,
          Data: JSON.stringify(menu),
          Target: this.MenuId
        }
      );
    }));

    this.allSuscription.add(this.menuService.CurrentPageTitle.subscribe((next) => {
      this.CurrentPageTitle = next;
    }));

    this.allSuscription.add(this.appService.userAvatar.subscribe(value => {
      this.profile = value ? value : this.profileDefault;
    }));
  }

  ngOnDestroy(): void {
    this.allSuscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges()
  }

  ngOnInit(): void {
    this.appService.changeCompany.subscribe(data=>{
      if(this.appService.changeCompany.getValue()) {
        this.ChangeCompany();
        this.appService.changeCompany.next(false);
      }
    })
    this.currentUser = this.authService.GetCurrentUser();

    const currentRoute = this.router.url;

    let found = this.routesWithoutMenu.filter(e => currentRoute.indexOf(e) != -1);

    this.ShowMenu = found.length == 0;

    this.ValidateSession();

    this.OnLoadData();
  }

  /*
  * Method to gets the meny and current user
   */
  OnLoadData(): void {
    this.clBlockUI.OnGet();
    let menu = this.menuService.Get();

    let userLogged = this.userService.GetUserLogged();

    forkJoin([
      menu.pipe(catchError(() => of(null))),
      userLogged.pipe(catchError(() => of(null))),


    ]).pipe(finalize(() => {
        this.clBlockUI.Drop();
      }),
    ).subscribe(([respMenu, resUserLogged]) => {
      if (respMenu) {
        let menu: IMenuItem[] = respMenu.Data;
        Repository.Behavior.SetStorage<IMenuItem[]>(menu, "KeyMenu");
        this.MenuNodes = menu;
        this.menuService.MenuItem.next(menu);
      }


      if (resUserLogged) {
        this.userLogged = resUserLogged.Data;

        if (this.userLogged?.Photo && this.userLogged.Photo.length) {
          this.profile = this.userLogged.Photo;
        }
      }




    });
  }

  /*
  * Method to gets the initial data such as exchange rate, company name and db code
   */
  GetCompanyInitialData() {

    this.clBlockUI.OnGet();
    this.companyService.GetCompanyInitialData().pipe(finalize(() => {
        this.clBlockUI.Drop();
      }),
      catchError((err) => {
        return [];
      })).subscribe((data) => {

        if(data.Data && data.Data.ExchangeRate == null){
          this.alertService.Toast({message: 'No se pudo obtener el tipo de cambio, revise que se encuentre configurado en SAP'});
        }



        if (data.Data ) {
          this.companyInitialData = data.Data;

          this.authService.SetCompanySelected(this.companyInitialData);
        }



    });
  }


  GetCompanyData(){
    this.companyInitialData = this.authService.GetCompanySelected();
    this.clBlockUI.OnGet();

    let companyCurrency = this.companyService.GetCompanyCurrency();
    let companyInitialData = this.companyService.GetCompanyInitialData();

    forkJoin([

      companyInitialData.pipe(catchError(error =>{
        this.companyCurrency = null;
        this.localCurrencies.length = 0;
        this.authService.SetCurrencies(this.localCurrencies);
        return of(null);
      })),
      companyCurrency.pipe(catchError(() => of(null))),


    ]).pipe(finalize(() => {
        this.clBlockUI.Drop();
      }),
    ).subscribe(([ resInitialData, respCurrency]) => {

     if(respCurrency){

       this.companyCurrency = respCurrency.Data

       if (this.companyCurrency) {
         const { MainCurncy, MainCurrencyName, MainISOCurrencySymbol } = this.companyCurrency;
         const { SysCurrncy, SysCurrencyName, SysISOCurrencySymbol } = this.companyCurrency;

         const  mainCurrency : ICurrency = {
           Id: MainCurncy,
           Name : MainCurrencyName,
           Symbol :  MainISOCurrencySymbol,
           IsMainCurrency: true
         }

         const sysCurrency: ICurrency  =  {
           Id: SysCurrncy,
           Name : SysCurrencyName,
           Symbol : SysISOCurrencySymbol,
           IsMainCurrency: false
         }

         if (mainCurrency && sysCurrency) {

           this.localCurrencies.length = 0;
           this.localCurrencies.push(mainCurrency, sysCurrency);

           this.authService.SetCurrencies(this.localCurrencies);
         }
       }
     }

     if(resInitialData){
       if(resInitialData.Data && resInitialData.Data.ExchangeRate == null){
         this.alertService.Toast({message: 'No se pudo obtener el tipo de cambio, revise que se encuentre configurado en SAP'});
       }

       if (resInitialData.Data ) {
         this.companyInitialData = resInitialData.Data;

         this.authService.SetCompanySelected(this.companyInitialData);
       }
     }

      this.appService.reloadData.next(true);
    });
  }

  /*
  * Show or Hide the menu
   */
  ToggleMenu(): void {
    this.Toggle = !this.Toggle;
    this.linkerervice.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    });
  }

  ClickMenuOption = (_event: ICLEvent): void => {
    if (_event) {
      const NODE_OPTION: IMenuItem = JSON.parse(_event.Data);
      switch (NODE_OPTION.Key) {
        case 'exit':
          this.router.navigateByUrl('/Login');
          this.authService.logout();
          break;
      }
    }
  };

  ValidateSession(){


    if(this.authService.isAuthenticated() && !this.authService.HaveCompanySelected()){
      this.OpenModalSelectCompany();
    }else{
      this.GetCompanyData();
    }

  }

  /**
   * Opens a modal to select a company.
   *
   * This method first checks if the current URL is included in the `validateUrls` array
   * and if the `reloadComp` property in `appService` is `false`. If both conditions are met,
   * it sets `reloadComp` to `true`. Otherwise, it opens the `SelectCompanyModalComponent` modal.
   * After the modal is closed, it calls the `GetCompanyData` method.
   */
  OpenModalSelectCompany(): void{
    if(this.validateUrls.some(x=> this.router.url.includes(x)) && !this.appService.reloadComp.getValue()){
      this.appService.reloadComp.next(true);
    }else{
      this.dialog.open(SelectCompanyModalComponent, {
        width: '650px',
        disableClose: true
      }).afterClosed().subscribe((res) => {
        if(res){
          this.GetCompanyData();
        }
      });
    }
  }

  /**
   * Opens a modal to change the current company.
   *
   * This method opens the `SelectCompanyModalComponent` modal. After the modal is closed,
   * it calls the `GetCompanyData` method and navigates the user to the home page (`/Home`).
   */
  ChangeCompany(): void {
    this.dialog.open(SelectCompanyModalComponent, {
      width: '650px',
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if(res){
        this.GetCompanyData();
        this.router.navigate(["/Home"]);
      }
    });
  }

  getCurrencySymbolFromUnicode(unicode: string): string {
    return String.fromCodePoint(parseInt(unicode, 16));
  }
}
