import {Injectable} from "@angular/core";
import {CLPrint, Repository, Structures} from '@clavisco/core';
import {Router} from "@angular/router";
import {IToken} from "../interfaces/token";
import {IUserDto} from "../interfaces/user";
import {CompanyInitialData} from "../interfaces/companys";
import {DataStorageService} from "./data-storage.service";
import {ICurrency} from "../interfaces/currency";
import {BehaviorSubject} from "rxjs";

/*
  Service responsible for handling authentication-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loginSubject = new BehaviorSubject<boolean>(false);
  authStatus$ = this.loginSubject.asObservable();

  constructor(
    private router: Router,
    private dataService: DataStorageService
  ) {
    if (this.GetCurrentUser()) {
      this.loginSubject.next(true);
    }
  }

  GetCurrentUser(): IUserDto {
    const TOKEN: IToken | null = Repository.Behavior.GetStorageObject<IToken | null>('CurrentSession');
    if (TOKEN && TOKEN.user) {
      const userObj = JSON.parse(TOKEN.user);
      Repository.Behavior.SetStorage<IUserDto>(userObj,"CurrentUser");
      return userObj;
    } else {
      return {} as IUserDto;
    }
  }

  public isAuthenticated(): boolean {

    const session = Repository.Behavior.GetStorageObject<any>('CurrentSession');

    return session ? new Date(session.expires) >= this.GetUTCNow() : false;
  }

  public GetCompanySelected(): CompanyInitialData{

    let selectedCompany = Repository.Behavior.GetSessionObject<CompanyInitialData>("CompanyData") as CompanyInitialData;

    return  selectedCompany ?? null;
  }

  public HaveCompanySelected(): boolean {

      return this.GetCompanySelected() != null;
  }

  private GetUTCNow(): Date {
    const dateNow: Date = new Date();
    return new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
  }

  public logout(): void {
    try {
      Repository.Behavior.DeleteStorageObject('CurrentSession');
      Repository.Behavior.DeleteStorageObject('CompanyData');
      Repository.Behavior.DeleteStorageObject('Currencies');
      sessionStorage.clear();
      this.loginSubject.next(false);
      this.router.navigateByUrl('/Login');
    } catch (error) {
      CLPrint(error, Structures.Enums.CL_DISPLAY.ERROR);
    }
  }

  public ValidateRol(role: string) : boolean{
    return this.GetCurrentUser().RoleName == role
  }

  public HavePermissionl(permission: string) : boolean{
   return  this.dataService.GetUserAccess().includes(permission);
  }
  public SetCompanySelected(company: CompanyInitialData){
    Repository.Behavior.SetSession<CompanyInitialData>(company, "CompanyData");
    this.loginSubject.next(true);
  }

  public SetCurrencies(currencies : ICurrency[]){
    Repository.Behavior.SetSession<ICurrency[]>(currencies, "Currencies");
  }

  public GetCurrencies(){
    let currencies = Repository.Behavior.GetSessionObject<ICurrency[]>("Currencies") as ICurrency[];

    return currencies;
  }

}
