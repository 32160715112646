import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {ICompany, IPermissionRoles} from "src/app/interfaces/companys";
import {IToken} from "../interfaces/token";

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  private localStorageS;
  private sessionStorage;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor() {
    this.localStorageS = localStorage;
    this.sessionStorage = sessionStorage;
    this.currentUserSubject = new BehaviorSubject<any>(this.GetCurrentCompany());
    this.currentUser = this.currentUserSubject.asObservable();
  };

  deleteStorage(name: string): void {
    this.localStorageS.removeItem(name);
  };
  deleteSessionStorage(name: string){
    this.sessionStorage.removeItem(name);
  };

  setSessionStorage(name: string, obj: any): void {
    this.sessionStorage.setItem(name, JSON.stringify(obj));
  };

  getSessionStorage<T>(name: string): T | null {
    return this.sessionStorage.getItem(name) ? (JSON.parse(this.sessionStorage.getItem(name)!) as T) :  null
  };

  getStorage(name: string): string | null {
    return this.localStorageS.getItem(name) ?? null;
  };

  getStorageAsNumber(key: string ): number {
    return Number(JSON.parse(atob(this.localStorageS.getItem("CurrentSession")!))[key]) ?? 0;
  };

  getStorageData(key: string ): string {
    const session =  atob(this.localStorageS.getItem("CurrentSession")!)
    return JSON.parse(session)[key] ?? "";
  };

  GetCurrentCompany(): ICompany  {
    return  JSON.parse(this.sessionStorage.getItem("SelectedCompany")!) as ICompany
  };

  GetCurrentPermissionsRoles(): IPermissionRoles{
    return  JSON.parse(this.sessionStorage.getItem("RoleAccess")!) as IPermissionRoles
  };

  // GetCurrentSession(): IToken| null{
  //
  //   const currentSession = JSON.parse(atob(this.getStorage('CurrentSession')!)) as IToken;
  //
  //   currentSession.Licence = this.encryptService.decryptData(currentSession.Licence)
  //
  //   return currentSession
  // }

  GetCurrentProcces(): string {
    return  JSON.parse(this.sessionStorage.getItem("Process")!)
  };

  GetUserAccess(): string[] {
    const currentSession: string | null = this.getStorage('CurrentSession')
    if(currentSession) {
      const TOKEN: IToken = JSON.parse(atob(this.getStorage('CurrentSession')!));
      if(!TOKEN) return []
      const permiss :string[] = JSON.parse(TOKEN.permissions)
      return permiss;
    }
    return [];
  }
}
