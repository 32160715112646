import {Injectable} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {finalize, forkJoin, map, Observable, of} from 'rxjs';
import {AssetsService} from "../services/assets.service";
import {OverlayService} from "@clavisco/overlay";
import {FileService} from "../services/file.service";
import {IFileConsultingDataResolver} from "../interfaces/resolver-data-interfaces";
import {StageService} from "../services/stage.service";
import {BusinessPartnerService} from "../services/business-partner.service";
import {IBusinessPartnerFilter} from "../interfaces/business-partner";
import {CommentService} from "../services/comment.service";
import {CommonService} from "../services/common.service";
import {CompanyService} from "../services/company.service";

@Injectable({
  providedIn: 'root'
})
export class FileColsuntingResolver implements Resolve<Observable<IFileConsultingDataResolver>> {

  filterBP :IBusinessPartnerFilter = {BpType:'Customer'};
  today: Date;
  lastWeek: Date;
  constructor(private assetService: AssetsService,
              private fileService: FileService,
              private clBlockUI: OverlayService,
              private stageService: StageService,
              private bpService: BusinessPartnerService,
              private commonService: CommonService,
              private companyService: CompanyService
              ) {

    this.today = new Date();
    this.lastWeek = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IFileConsultingDataResolver> {

    return this.RequestRequiredData();
  }

  RequestRequiredData(): Observable<IFileConsultingDataResolver> {

    this.clBlockUI.OnGet('Obteniendo informacion requerida');

    return forkJoin([
      this.fileService.GetDataCollectorFile(),
      this.assetService.GetFileStatusOptions(),
      this.assetService.GetBusinessUnit(),
      this.stageService.Get(),
      this.bpService.Get(this.filterBP),
      this.fileService.GetFilesByUser({
        StartDate: this.commonService.FormatDate( this.lastWeek.toString()),
        EndDate: this.commonService.FormatDate( this.today.toString()),
        StartPos: 0,
        RowsReturned: 10
      }),
    ]).pipe(map(([DataCollectorFile, fileStatusOptions, businessUnits,
                              stages,  bussinesPatners, files]) => {
        return {
          DataCollectorFile: DataCollectorFile,
          FileStatusOptions: fileStatusOptions,
          BusinessUnits: businessUnits,
          Stages: stages,
          BussinesPatners: bussinesPatners,
          Files: files
        } as IFileConsultingDataResolver
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    );
  }
}
