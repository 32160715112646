import { Injectable } from '@angular/core';
import {IMyFilesFilter} from "../interfaces/file";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {Router} from '@angular/router';
import {PinPad} from "@clavisco/core";
import Enums = PinPad.Enums;
import {IEnum} from "../interfaces/enum";
import {CurrencyPipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private router: Router,
              private currencyPipe: CurrencyPipe
              ) { }

  GetParamsByObject<T>(filterParams : T | null, propertiesToIgnore :string = "") : HttpParams{
    let params = new HttpParams();

    const propertiesToIgnoreArray = propertiesToIgnore.split(',')

    if (filterParams != null) {
      // Iterate through properties of filterParams
      Object.keys(filterParams).forEach(key => {
        const value = (filterParams as any)[key];

        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            // If value is an array, iterate and add each element
            value.forEach((element: any) => {
              params = params.append(key, element);
            });
          } else {
            // If value is not an array, add it directly

            if(!propertiesToIgnoreArray.includes(key)){
              params = params.set(key, value);
            }

          }
        }
      });
    }

    return params;

  }

  /**
   * Transform the date to send to the api in the formt yyyy-MM-dd
   */
  TransformDate(inputDate: Date): string {
    const year = inputDate.getFullYear();
    const month = this.PadZero(inputDate.getMonth() + 1);
    const day = this.PadZero(inputDate.getDate());
    return `${year}-${month}-${day}`;
  }

  /**
   * Pads a single-digit number with a leading zero, converting it into a two-digit string.
   */
  PadZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  FileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
      reader.onerror = reject;
    });
  FormatDate(inputDate: string) {
    const inputDateFormat = new Date(inputDate);
    const year = inputDateFormat.getFullYear();
    const month = (inputDateFormat.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDateFormat.getDate().toString().padStart(2, '0');
    const hours = inputDateFormat.getHours().toString().padStart(2, '0');
    const minutes = inputDateFormat.getMinutes().toString().padStart(2, '0');
    const seconds = inputDateFormat.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  OpenNewTabAndNavigate(target: string): void {
    // Replace 'target-route' with the route path you want to navigate to
    const newTab = window.open(target, '_blank');
  }

  DefineDescriptionHeader(_descriptions: { OnError: string, OnSuccess?: string, OnDetail?: string}, _headers: HttpHeaders | null | undefined = undefined): HttpHeaders
  {
    if(_headers)
    {
      return _headers.set('Cl-Request-Error-Description', _descriptions.OnError).set('Cl-Request-Success-Description', _descriptions.OnSuccess??"").set('Cl-Request-Detail-Description', _descriptions.OnDetail??"");
    }
    return new HttpHeaders().set('Cl-Request-Error-Description', _descriptions.OnError).set('Cl-Request-Success-Description', _descriptions.OnSuccess??"").set('Cl-Request-Detail-Description', _descriptions.OnDetail??"");
  }

  private currencyConfig: { [key: string]: { code: string, locale: string, symbol: string } } = {
    'CRC': { code: 'CRC', locale: 'es-CR', symbol: '₡' },
    'USD': { code: 'USD', locale: 'es-US', symbol: '$'  },
    'PAB': { code: 'PAB', locale: 'es-PA', symbol: 'B/'},
    'NIO': { code: 'NIO', locale: 'es-NI',  symbol: 'C$' },
    'GTQ': { code: 'GTQ', locale: 'es-GT', symbol: 'Q' }
  };

  TransformAmount( currencyCode: string | undefined, amount: number): string {

    if(!currencyCode){
      return amount.toString();
    }

    const config = this.currencyConfig[currencyCode];

    if (!config) {
      return amount.toString();
    }

    return this.currencyPipe.transform(amount, config.code, 'symbol-narrow', '1.2-2', config.locale) ?? amount.toString();

  }

  GetCurrencySymbol(currencyCode: string | undefined) : string {
    if(!currencyCode){
      return '';
    }

    const config = this.currencyConfig[currencyCode];

    return config.symbol ;
  }

  /**
   * Method to retrieve the Unicode locale ID
   * */
  GetCurrencyLocal(currencyCode: string | undefined): string {
    if(!currencyCode){
      return "";
    }
    const config = this.currencyConfig[currencyCode];
    return config.locale
  };

}

