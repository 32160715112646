import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {Repository, Structures} from "@clavisco/core";
import {IToastGlobalConfiguration} from "@clavisco/alerts";
import {Login} from "@clavisco/login";
import * as LogRocket from 'logrocket';
import {CurrencyPipe} from "@angular/common";


import {registerLocaleData} from "@angular/common";
import localeEsCR from '@angular/common/locales/es-CR';
import localeEsPA from '@angular/common/locales/es-PA';
import localeEsNI from '@angular/common/locales/es-NI';
import localeEsUS from '@angular/common/locales/es-US';
import {ITableGlobalConfiguration} from "@clavisco/table";



registerLocaleData(localeEsPA, 'es-PA');
registerLocaleData(localeEsCR, 'es-CR');
registerLocaleData(localeEsNI, 'es-NI');
registerLocaleData(localeEsUS, 'es-US');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit{
  title = 'cl_fa_fa_fil';
  apiUrl = environment.ApiUrl;

  constructor() {
    if (environment.production) {
      LogRocket.init(environment.LogRocketId);
    }
  }

  ngOnInit(): void {
    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      apiUrl: this.apiUrl
    } as any)

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.ALERTS,
      settings: {
        duration: 8000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        cssClasses: ['alert-text']
      } as IToastGlobalConfiguration,
      override: true
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'overlaysConfiguration',
      value: JSON.stringify({
        loginMessage: 'Iniciando Sesión',
        changePasswordMessage: 'Cambiando Contraseña',
        sendRecoverEmailMessage: 'Enviando Correo de Recuperación',
        recoverPasswordMessage: 'Actualizando Contraseña'
      } as Login.Structures.IOverlaysGlobalConfiguration)
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'changePassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}api/Users/ChangePassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'recoverPassword',
      value: {
        endpointToRequest: `${environment.ApiUrl}api/Users/ChangeRecoveryPassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'sendRecoverPasswordEmail',
      value: {
        urlVariableToReplaceWithEmail: '#EMAIL#',
        endpointToRequest: `${environment.ApiUrl}api/Users/#EMAIL#/RecoveryPassword`
      }
    })

    Repository.Behavior.SetTokenConfiguration({ token: 'NotificationCenter', setting: 'max', value: '10' });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.TABL,
      settings: {
        backgroundTransparent: true
      } as ITableGlobalConfiguration
    });
  }
}
