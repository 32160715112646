import {ModuleWithProviders, NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {PagesComponent} from "./pages/pages.component";
import {AuthGuard} from "./guards/auth.guard";
import {FileColsuntingResolver} from "./resolvers/file-colsunting.resolver";
import {MyFilesResolver} from "./resolvers/my-files.resolver";
import {PaymentRequestConsultationResolver} from "./resolvers/payment-request-consultation.resolver";
import {PaymentRequestInfoResolver} from "./resolvers/payment-request-info.resolver";
import {HistoryResolver} from "./resolvers/history.resolver";
import {UsersResolver} from "./resolvers/users.resolver";

const routes: Routes = [
  {
    path: 'Login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginContainerModule)
  },
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'SelectCompany', loadChildren: () => import('./pages/select-company-modal/select-company-modal.module').then(m => m.SelectCompanyModalModule ), canActivate: [AuthGuard]},
      { path: 'Home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule ), canActivate: [AuthGuard]},
      { path: 'CreateFile', loadChildren: () => import('./pages/create-files/create-files.module').then(m => m.CreateFilesModule ), canActivate: [AuthGuard]},
      { path: 'FileConsultation', loadChildren: () => import('./pages/file-consultation/file-consultation.module').then(m => m.FileConsultationModule ), canActivate: [AuthGuard], resolve:{DataResolved:FileColsuntingResolver}},
      { path: 'MyFiles', loadChildren: () => import('./pages/my-files/my-files.module').then(m => m.MyFilesModule ), canActivate: [AuthGuard], resolve:{DataResolved:MyFilesResolver}},
      { path: 'PaymentRequestConsultation', loadChildren: () => import('./pages/payment-request-consultation/payment-request-consultation.module').then(m => m.PaymentRequestConsultationModule), canActivate: [AuthGuard], resolve:{DataResolved: PaymentRequestConsultationResolver}},
      { path: 'PaymentRequestDetails/:id', loadChildren: () => import('./pages/payment-request-details/payment-request-details.module').then(m => m.PaymentRequestDetailsModule), canActivate: [AuthGuard]},
      { path: 'PaymentRequestInfo', loadChildren: () => import('./pages/payment-request-info/payment-request-info.module').then(m => m.PaymentRequestInfoModule), canActivate: [AuthGuard], resolve:{DataResolved:PaymentRequestInfoResolver} },
      { path: 'File', loadChildren: () => import('./pages/create-files/create-files.module').then(m => m.CreateFilesModule ), canActivate: [AuthGuard]},
      { path: 'ConfigCompany', loadChildren: () => import('./pages/companys-config/companys-config.module').then(m => m.CompanysConfigModule), canActivate: [AuthGuard] },
      { path: 'ConfigConnections', loadChildren: () => import('./pages/sap-connections-config/sap-connections-config.module').then(m => m.SapConnectionsConfigModule), canActivate: [AuthGuard] },
      { path: 'UsersConfig', loadChildren: () => import('./pages/users-config/users-config.module').then(m => m.UsersConfigModule), canActivate: [AuthGuard], resolve:{DataResolved:UsersResolver} },
      { path: 'Permissions', loadChildren: () => import('./pages/permissions-config/permissions-config.module').then(m => m.PermissionsConfigModule), canActivate: [AuthGuard]},
      { path: 'Historical', loadChildren: () => import('./pages/historical/historical.module').then(m => m.HistoricalModule), canActivate: [AuthGuard], resolve:{DataResolved:HistoryResolver} },
      { path: 'Notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthGuard] },
      { path: '', redirectTo: '/Login', pathMatch: 'full' },
    ]
  }
];


export const AppRoutingModule: ModuleWithProviders<Route> = RouterModule.forRoot(routes, {
  useHash: true,
  onSameUrlNavigation: 'reload'
});
