import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {
  IFile,
  IFileComplete,
  IFileLine, IFilePagined,
  IFileRender,
  IFilesConsultingFilter,
  IMyFilesFilter,
  IUnarchiveFileDto
} from '../interfaces/file';
import {DraftResponse, IPaymentRequestFilter} from "../interfaces/payment-request";
import {CommonService} from "./common.service";
import {IDataCollectorFile} from "../interfaces/item";
import {IProfit} from "../interfaces/profit";

/*
  Service responsible for handling file-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class FileService {

  private readonly CONTROLLER = 'api/File';

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }



  GetFilesWithPendingPayments(filterParams: IPaymentRequestFilter | null): Observable<ICLResponse<IFileRender[]>> {

    const params = this.commonService.GetParamsByObject<IPaymentRequestFilter>(filterParams);

    return this.http.get<ICLResponse<IFileRender[]>>(`${this.CONTROLLER}/GetFilesWithPendingPayments`, {params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener archivo con pagos pendientes`
      })});
  }

  GetFilesByUser(filterParams: IMyFilesFilter | null): Observable<ICLResponse<IFilePagined[]>> {

    const params = this.commonService.GetParamsByObject<IMyFilesFilter>(filterParams);

    return this.http.get<ICLResponse<IFilePagined[]>>(`${this.CONTROLLER}/FilesByUser`, {params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener archivos por usuario`
      })});
  }

  GetFileLinesByFile(fileId: number): Observable<ICLResponse<IFileLine[]>> {
    const params = new HttpParams()
      .set('fileId', fileId);
    return this.http.get<ICLResponse<IFileLine[]>>(`${this.CONTROLLER}/LinesByFile`, {params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener archivos por linea`
      })});
  }

  GetById(Id?: number): Observable<ICLResponse<IFile>> {
    return this.http.get<ICLResponse<IFile>>(`${this.CONTROLLER}/${Id}`,{headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener archivos`
      })});
  }

  Get(): Observable<ICLResponse<IFile[]>> {
    return this.http.get<ICLResponse<IFile[]>>(`${this.CONTROLLER}`, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener archivos`
      })});
  }

  Filter(filterParams: IFilesConsultingFilter | null): Observable<ICLResponse<IFilePagined[]>> {

    const params = this.commonService.GetParamsByObject<IFilesConsultingFilter>(filterParams);

    return this.http.get<ICLResponse<IFilePagined[]>>(`${this.CONTROLLER}/Filter`, {params: params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener archivos filtrados`
      })});
  }

  SaveFile(file: IFileComplete): Observable<ICLResponse<IFile>> {
    return this.http.post<ICLResponse<IFile>>(`${this.CONTROLLER}/SaveFile`, file, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al guardar archivo`
      })});
  }

  ProcessFile(file: IFileComplete): Observable<ICLResponse<DraftResponse>> {
    return this.http.post<ICLResponse<DraftResponse>>(`${this.CONTROLLER}/ProcessFile`, file, {
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al procesar archivo`})
    });
  }

  CloseFile(file: IFileComplete): Observable<ICLResponse<DraftResponse>> {
    return this.http.post<ICLResponse<DraftResponse>>(`${this.CONTROLLER}/CloseFile`, file, {
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al alchivar expediente`})
    });
  }

  ChangeStageFile(file: IFileComplete): Observable<ICLResponse<IFile>> {
    return this.http.post<ICLResponse<IFile>>(`${this.CONTROLLER}/ChangeStageFile`, file, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al cambiar etapa por archivo`
      })});
  }

  //todo Se debe revisar los headers
  ChangeAssignamentByFile(fileId: number, targetUser: number, stage: number): Observable<ICLResponse<boolean>> {
    let params = {
      FileId: fileId,
      TargetUser: targetUser,
      Stage: stage
    }

    return this.http.patch<ICLResponse<boolean>>(`${this.CONTROLLER}/ChangeAssignamentByFile`, params, /*{headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al cambiar asignación por archivo`
      })}*/);
  }

  UnArchiveFile(fileDto : IUnarchiveFileDto): Observable<ICLResponse<boolean>> {
    return this.http.patch<ICLResponse<boolean>>(`${this.CONTROLLER}/UnArchiveFile`, fileDto, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al desarchivar expediente`
      })});
  }

  GetDataCollectorFile(): Observable<ICLResponse<IDataCollectorFile[]>> {
    return this.http.get<ICLResponse<IDataCollectorFile[]>>(`${this.CONTROLLER}/DataCollectorFile`, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener informacion del recolector de archivos`
      })});
  }

  GetProfitsByFileId(fileId?: number): Observable<ICLResponse<IProfit[]>> {
    return this.http.get<ICLResponse<IProfit[]>>(`${this.CONTROLLER}/GetProfitsByFile/${fileId}`, {
      headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener ventas por archivo}`
      })
    });
  }
}
