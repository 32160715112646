/*
  * type of File
*/
export enum IDocumentType {
  File = 1,
  PaymentRequest = 2
}


/*
  * options of Stage
*/
export enum IStageOptions {
  Next = 1,
  Previous = 2,
  Current = 3
}

/*
  * types of status
 */
export enum IStatusType{
  Status = 1,
  ResolutionStatus = 2
}

/*
  * status of Payment Request
*/
export enum IPaymentRequestStatus {
  InProgress = "InProgress",
  Created = "Created",
  Pending = "Pending",
  Rejected = 'Rejected',
  Declined = 'Declined',
  Closed = 'Closed',
  Accepted = 'Accepted'
}

/*
  * type of Payment Request
*/
export enum ITypesRequest {
  FenixCash = 'FE',
  TercerCredit = 'TC',
  FenixCard = 'FT',
  FenixCredit = 'FC',
  TercerCash = 'TE',
  TercerCard = 'TT',
  TaxPayment = 'PI'
}

/*
  * payment condition by payment request
*/
export enum IPaymentCondition{
  Credit = 'Credit',
  Card = 'Card',
  Cash = 'Cash'
}

/*
  * payment type by payment request
*/
export enum IPaymentType {
  Fenix= 'Fenix',
  Tercer = 'Tercer'
}

/*
  * business unit by File
*/
export enum IBusinessUnitType {
  Customs = 1,
  International = 2
}

/*
  * business unit name by File
*/
export enum IBusinessUnit {
  Customs = 'A',
  International = 'I'
}


/*
  * sum type by gets costs or profits
*/
export enum ISumType {
  Amount = 1,
  IVA = 2,
  Total = 3
}

/*
  * status by File
*/
export enum IFileStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Closed = 'Closed',
  UnArchived = 'UnArchived'
}

/*
  * filters for a file
*/
export enum IDataCollectorType {
  Status = 'FAFile_Status',
  Incoterm = 'FAFile_Incoterms',
  Country = 'FAFile_Countries',
  WareHouse = 'FAFILE_Alm_Fiscal',
  Agency = 'FAFile_Agencias',
  Regimen = 'FAFile_Regimen',
  PreviusPersonal= 'FAFILE_PrePers',
  TruckType = 'FAFILE_TypeTruck',
  ShipmentType = 'FAFile_TypeShipment',
  ShippingMode = 'FAFILE_ModeShip',
  Airline = 'FAFILE_ShipAir'

}

/*
  * type of stage
*/
export enum IStagesType {
  NA = 0,
  Pedimentacion = 1,
  Transmision = 2,
  Transporte = 3,
  Facturador = 4,
  Operativo = 5,
  Coordinacion = 6,
  Gerencia = 7
}



export enum HistoryActions {
  Created = 'Creacion'
}

/**
 * Enumeration for file history status.
 */
export enum FileHistoryStatus {
  // Add your file history statuses here
}


export enum NotificationType {
  AssignedFile = 1,
  PaymentRequestResolution

}


/*

  User permissions
 */
export enum UserPermissions {
  ArchiveFiles = 'F_ArchieveFile',
  UnarchieveFiles= 'F_UnarchieveFiles',
  ChangeAssign= 'F_ChangeAssignament',
  ShowBalanceField = 'F_ShowBalanceField',
  ShowCreditLimitField = 'F_ShowCreditLimitField',
  ShowIsExpiredField = 'F_ShowIsExpiredField',
  ShowAvailableCreditField = 'F_ShowAvailableCreditField',
  ShowPymntGroupField = 'F_ShowPymntGroupField',
  UploadAttPaymentRequest = 'F_UploadAttPaymentRequest',
  UploadAttFile = 'F_UploadAttFile',
  UploadAttPaymentRequestArchived = 'F_UploadAttPaymentRequestArchived',
  UploadAttFileArchived = 'F_UploadAttFileArchived',
  ShowTaxPaymentRequest = 'F_ShowTaxPaymentRequest',
  CreateFile = 'F_CreateFile',
  CanEditAll = 'F_CanEditAll',
  IsOperativo = 'F_IsOperativo',
  IsPedimentador = 'F_IsPedimentador',
  IsTransmisor = 'F_IsTransmisor',
  IsTransportista = 'F_IsTransportista',
  AddItems = 'F_AddItems',
  InactivePaymentRequest = 'F_InactivePaymentRequest',
  RequestPaymentRequest = 'F_RequestPaymentRequest',
  ShowPaymentRequestInfo = 'F_ShowPaymentRequestInfo',
  AllItems = 'F_AllItems',
  ChangeStage = 'F_ChangeStage',
  AddComments = 'F_AddComments',
  CanAlwaysEditPaymentRequest = 'F_CanAlwaysEditPaymentRequest'
}


/*
  * Transport types in custom files
*/
export enum ITransportType{
  Fenix = 'Fenix',
  Sub = 'Sub',

}


/**
 * Represent the types of payment request that can be processed
 */
export enum PaymentRequestTypes
{
  /**
   * Indicates that the payment requests in process are normal request
   */
  NormalRequest = 1,

  /**
   * Indicates that the payment requests in process are for taxes
   */
  TaxRequest = 2
}


/**
 * Represent the IDs of the database entities
 */
export enum DefaultDatabaseEntitiesIdentifiers
{
  /**
   * Represent the default database entity identifier when is not found or is not specified
   */
  NotSpecified = -1
}

/**
 * Represent the types of currencies
 */
export enum ICurrencies {
  USD = 'USD'
}
