import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpStatusCode} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, EMPTY, map, Observable, tap, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {CompanyInitialData, ICompany} from "../interfaces/companys";
import {Repository} from "@clavisco/core";
import {AuthenticationService} from "../services/authentication.service";
import {CLModalType, ModalService} from "@clavisco/alerts";

@Injectable()
export class AppInterceptor implements HttpInterceptor {


  constructor(
    private authService: AuthenticationService,
    private modalService: ModalService
  ) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.ExcludeUrl(req.url)) {
      return next.handle(req).pipe(
        catchError((error: any) => throwError(() => error))
      );
    }

    let clonedRequest: HttpRequest<unknown> = req.clone();

    // Valido que no tenga el header que indica si tiene archivos para no enviar el content type
    if(!clonedRequest.headers.has('Request-With-Files'))
    {
      let headers: HttpHeaders = clonedRequest.headers.set('Content-Type', 'application/json');
      clonedRequest = clonedRequest.clone({headers});
    }

    let selectedCompany = this.authService.GetCompanySelected();

    if (selectedCompany) {

      let headers: HttpHeaders = clonedRequest.headers.set('cl-company-id', selectedCompany?.Id?.toString() || '0');

      clonedRequest = clonedRequest.clone({headers});

    }else{
      if (this.ExcludeRequireCompany(req.url)) {
        this.modalService.Continue({
          title: 'No se ha podido recuperar la compañía seleccionada. Esto puede haber ocurrido por un error en la sesión. Por favor, seleccione una compañía para continuar o intente refrescar la página.',
          type: CLModalType.INFO
        }).subscribe();
        return EMPTY;
      }
    }


    clonedRequest = clonedRequest.clone({url: `${environment.ApiUrl}${req.url}`});

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: any) => {
        console.log(error)
        throw new Error(error.error.Message);
      })
    );
  }

  private ExcludeUrl(url: string): boolean {
    const excludedUrls: string[] = ['assets', 'token', 'ChangeRecoverPassword', 'RecoveryPassword', 'ChangePassword'];
    return excludedUrls.some(x => url.includes(x));
  }

  private ExcludeRequireCompany(url: string): boolean {
    const noCompanyRequired: string[] = ['GetUserLoggedData', 'Menu', 'AssignedCompanies', 'Home'];
    return !noCompanyRequired.some(x => url.includes(x));
  }

}
