import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {finalize, forkJoin, map, Observable, of} from 'rxjs';
import {AssetsService} from "../services/assets.service";
import {FileService} from "../services/file.service";
import {OverlayService} from "@clavisco/overlay";
import {StageService} from "../services/stage.service";
import {IMyFilesDataResolver, IPaymentRequestConsultingDataResolver} from "../interfaces/resolver-data-interfaces";
import {CommonService} from "../services/common.service";
import {CompanyService} from "../services/company.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentRequestConsultationResolver implements Resolve<IPaymentRequestConsultingDataResolver> {

  today: Date;
  lastWeek: Date;
  constructor(private assetService: AssetsService,
              private clBlockUI: OverlayService,
              private stageService: StageService,
              private fileService: FileService,
              private commonService: CommonService
              ) {
    this.today = new Date();
    this.lastWeek = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7);
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPaymentRequestConsultingDataResolver> {
    return this.RequestRequiredData();
  }
  RequestRequiredData(): Observable<IPaymentRequestConsultingDataResolver> {

    this.clBlockUI.OnGet('Obteniendo informacion requerida');

    return forkJoin([
      this.assetService.GetBusinessUnit(),
      this.stageService.Get(),
      this.assetService.GetCurrencies(),
      this.fileService.GetFilesWithPendingPayments({
        StartDate: this.commonService.TransformDate(this.lastWeek),
        EndDate:this.commonService.TransformDate(this.today),
      }),
    ]).pipe(map(([ businessUnits,stages, currencies, files]) => {
        return {
          BusinessUnit: businessUnits,
          Stages: stages,
          Currencies: currencies,
          Files: files
        } as IPaymentRequestConsultingDataResolver
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    )
  }
}
