import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AlertsService, CLModalType, CLToastType, ModalService} from '@clavisco/alerts';
import {Observable} from 'rxjs';
import {MenuService} from "../services/menu.service";
import {AuthenticationService} from "../services/authentication.service";
import {DataStorageService} from "../services/data-storage.service";
import {IMenuItem} from "../interfaces/menu-node";

@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private menuService: MenuService,
    public auth: AuthenticationService,
    private modalService: ModalService,
    private dataStorageService: DataStorageService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = state.url.substring(1);


    if (this.auth.isAuthenticated()) {
      if (this.menuService.GetLocalMenu()) {
        const node: IMenuItem | null = this.menuService.FindNodeByRoute(this.menuService.GetLocalMenu(), path);
        if (node) {
          if(node.Permission === '') {
            this.menuService.SetCurrentPageTitle(path);
            return true;
          }
          if (!this.dataStorageService.GetUserAccess().includes(node.Permission) && path != 'Home') {
            this.modalService.Continue({
              title: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
              type: CLModalType.ERROR
            }).subscribe();
            return false;
          }
        } else {
          const lastSlashIndex: number = path.lastIndexOf('/');
          const newPath: string = path.slice(0, lastSlashIndex);
          if (this.dataStorageService.GetUserAccess().includes(newPath)) {
            return true;
          }
          this.modalService.Continue({
            title: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
            type: CLModalType.ERROR
          }).subscribe();
          return false;
        }
      }


      this.menuService.SetCurrentPageTitle(path);

      return true;
    } else {
      this.auth.logout();
      this.modalService.Continue({
        title: 'Su sesión ha expirado o no está autenticado',
        subtitle: 'Por favor, inicie sesión nuevamente para continuar.',
        type: CLModalType.ERROR,
        disableClose:true
      }).subscribe();
      return false;
    }

  }

}
