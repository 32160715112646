import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {finalize, forkJoin, map, Observable, of} from 'rxjs';
import {IBusinessPartnerFilter} from "../interfaces/business-partner";
import {AssetsService} from "../services/assets.service";
import {FileService} from "../services/file.service";
import {OverlayService} from "@clavisco/overlay";
import {StageService} from "../services/stage.service";
import {BusinessPartnerService} from "../services/business-partner.service";
import {IHistoryDataResolver} from "../interfaces/resolver-data-interfaces";

@Injectable({
  providedIn: 'root'
})
export class HistoryResolver implements Resolve<IHistoryDataResolver> {
  filterBP :IBusinessPartnerFilter = {BpType:'Customer'};
  constructor(private assetService: AssetsService,
              private clBlockUI: OverlayService,
            
  ) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IHistoryDataResolver> {
    return this.RequestRequiredData();
  }
  RequestRequiredData(): Observable<IHistoryDataResolver> {

    this.clBlockUI.OnGet('Obteniendo informacion requerida');


    return forkJoin([
      this.assetService.GetBusinessUnit(),
    ]).pipe(map(([businessUnits]) => {
        return {
          BusinessUnits: businessUnits

        } as IHistoryDataResolver
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    )
  }
}
