import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {finalize, forkJoin, map, Observable, of} from 'rxjs';
import {AssetsService} from "../services/assets.service";
import {FileService} from "../services/file.service";
import {OverlayService} from "@clavisco/overlay";
import {StageService} from "../services/stage.service";
import {BusinessPartnerService} from "../services/business-partner.service";
import {IMyFilesDataResolver, IUsersDataResolver} from "../interfaces/resolver-data-interfaces";
import {UserService} from "../services/user.service";
import {RoleService} from "../services/role.service";

@Injectable({
  providedIn: 'root'
})
export class UsersResolver implements Resolve<IUsersDataResolver> {

  constructor(private assetService: AssetsService,
              private fileService: FileService,
              private clBlockUI: OverlayService,
              private rolesService: RoleService,
              private userService: UserService,
  ) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUsersDataResolver> {
    return this.RequestRequiredData();
  }

  RequestRequiredData(): Observable<IUsersDataResolver> {

    this.clBlockUI.OnGet('Obteniendo informacion requerida');

    return forkJoin([
      this.rolesService.Get(),
      this.userService.GetUsersByFilter(null),
    
    ]).pipe(map(([roles,users]) => {
        return {
          Roles: roles,
          Users: users
        } as IUsersDataResolver
      }),
      finalize(() => {
        this.clBlockUI.Drop();
      })
    )
  }
}
