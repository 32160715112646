import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {CLModalType, ModalService} from '@clavisco/alerts';

enum CustomHttpStatusCode {
  NoConnection
}

type CombinedCodes = HttpStatusCode | CustomHttpStatusCode;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  errorHandlers: { [key in CombinedCodes]?: (error: any) => any } = {
    [HttpStatusCode.Unauthorized]: () => ({
      title: 'Sin autorización',
      subtitle: 'Posiblemente debido a que la sesión es inválida, no tienes permiso para realizar esta acción o las credenciales de SAP son incorrectas',
      type: CLModalType.INFO
    }),
    [CustomHttpStatusCode.NoConnection]: () => ({
      title: 'Sin conexion con el servidor',
      subtitle: 'Error al intentar conectar con el servidor',
      type: CLModalType.ERROR
    }),
    [HttpStatusCode.InternalServerError]: (error) => ({
      title: 'Error',
      subtitle: error['error']['Message'],
      type: CLModalType.ERROR
    }),
    [HttpStatusCode.MethodNotAllowed]: (error) => ({
      title: 'Error',
      subtitle: error['error']['Message'],
      type: CLModalType.ERROR
    }),
    [HttpStatusCode.Conflict]: (error) => ({
      title: 'Error',
      subtitle: error['error']['Message'],
      type: CLModalType.ERROR
    }),
    [HttpStatusCode.InternalServerError]: (error) => ({
      title: 'Error',
      subtitle: error['error']['Message'],
      type: CLModalType.ERROR
    }),
    [HttpStatusCode.BadRequest]: (error) => ({
      title: 'Error',
      subtitle: error['error']['Message'],
      type: CLModalType.ERROR
    }),
    [HttpStatusCode.NotFound]: (error) => ({
      title: 'No se encontró el recurso solicitado',
      subtitle: error['message'],
      type: CLModalType.ERROR
    }),
  };

  excludeURLs = ['token'];

  constructor(
    private modalService: ModalService) {
  }

  private handleError(error: any) {

    if (this.excludeURLs.some(e => error.url.includes(e))) {
      return throwError(() => error);
    }



    const statusCode = error['status'] as HttpStatusCode;


    const handler = this.errorHandlers[statusCode];

    const message = error['error']['Message'] != null &&  error['error']['Message'] != undefined ?  error['error']['Message']  : ''

    let modalConfig = handler ? handler(error) : {
      title: 'Error',
      subtitle: `Ocurrió un error inesperado: ${message}`  ,
      type: CLModalType.ERROR
    };

    this.modalService.Continue(modalConfig).subscribe();

    return throwError(() => error);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => this.handleError(error))
    );
  }

}
