import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  IBusinessPartner,
  IBusinessPartnerAccountFilter,
  IBusinessPartnerAccountInfo,
  IBusinessPartnerFilter
} from "../interfaces/business-partner";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {CommonService} from "./common.service";
/*
  Service responsible for handling business partner-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerService {

  private readonly CONTROLLER = 'api/BusinessPartner'

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }


  public Get(filter: IBusinessPartnerFilter): Observable<ICLResponse<IBusinessPartner[]>> {
    const params = new HttpParams()
      .set('BpType', filter.BpType || '')

    return this.http.get<ICLResponse<IBusinessPartner[]>>(`${this.CONTROLLER}`, {params: params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener socios de negocios`
      })})
  }


  public GetBusinessPartnersAccount(filter: IBusinessPartnerAccountFilter): Observable<ICLResponse<IBusinessPartnerAccountInfo>> {
    const params = new HttpParams()
      .set('CardCode', filter.CardCode || '')

    return this.http.get<ICLResponse<IBusinessPartnerAccountInfo>>(`${this.CONTROLLER}/GetBusinessPartnersAccountData`, {params: params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener  la informacion de la cuenta del socio de negocio`
      })})
  }
}
