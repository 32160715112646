
import {IRole} from "./role";
import {Structures} from "@clavisco/core";
import IBaseEntity = Structures.Interfaces.IBaseEntity;
import ICLCompany = Structures.Interfaces.ICLCompany;

/** Interface representing a company, extending the base entity */
export interface ICompany extends ICLCompany {
  /** Company status */
  Status: string;

  /** Name of the database */
  ConnectionId?: number;

  /** Name of the database */
  Profit?: number;

  /** The Tax Card Code associated with the company for tax-related requests. */
  TaxCardCode?: string;
  /** The Serie order of the payment  requests. */
  SerieOrder?: string;
  /** The Serie of payment draft. */
  SeriePaymentDraft?: string;
  /** The Serie of purchase order if  payment draft. */
  SeriePurchaseOrder?: string;

}

export interface IAssignCompany extends  ICompany {
  /** The IsAssigned indicating company is Assigned */
  IsAssigned: boolean;
}


/** Interface representing filters for fetching companies */
export interface ICompanyFilter {
  /** Company name filter for query */
  CompanyName: string;
  /** String indicating whether the company is active or not */
  IsActive: string;
  /** Name of the database */
  DatabaseCode: string;

}

/** Interface representing a status with boolean value and view value */
export interface IStatus {
  /** Boolean value indicating the status */
  value: boolean | null;
  /** Descriptive view value of the status */
  viewValue: string;
}

/** Interface representing permissions and roles */
export interface IPermissionRoles {
  /** Array of access permissions */
  Access: string[];
  /** Array of role entities */
  Roles: IRole[];
}



/**
 * Represents the initial data related to the company.
 */
export interface CompanyInitialData {
  /**
   * The id associated with the company.
   */
  Id: number;
  /**
   * The code of the database associated with the company.
   */
  DatabaseCode: string;

  /**
   * The name of the company.
   */
  CompanyName: string;

  /**
   * The exchange rate information for the company.
   */
  ExchangeRate: ExchangeRate;
  /**
   * The profit information for the company.
   */
  Profit: number;
}




/**
 * Represents the currencies of  the companies.
 */
export interface CompanyCurrency {
  /**
   * The main currency of company.
   */
  MainCurncy: string;
  /**
   * The sys currency of the company, the secondary currency.
   */
  SysCurrncy: string;

  /**
   * The main currency symbol of company.
   */
  MainISOCurrencySymbol: string;
  /**
   * The sys currency  symbol of the company, the secondary currency.
   */
  SysISOCurrencySymbol: string;

  /**
   * The main currency  name of company.
   */
  MainCurrencyName: string;
  /**
   * The sys currency of the company, the secondary currency.
   */
  SysCurrencyName: string;


}




/**
 * Represents an exchange rate of a company's currency.
 */
export interface ExchangeRate {
  /**
   * The unique identifier of the exchange rate.
   */
  Currency: string;

  /**
   * The exchange rate.
   */
  Rate: number;

  /**
   * The date of the exchange rate.
   */
  RateDate: string; // You may need to adjust the type based on your needs, for example, use Date type if RateDate is a date string.
}
