import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IEnum} from "../interfaces/enum";
import {ICurrency} from "../interfaces/currency";

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  private readonly CONTROLLER = 'assets/data/'

  constructor(
    private http: HttpClient
  ) {
  }

  GetFileStatusOptions(): Observable<IEnum[]> {
    return this.http.get<IEnum[]>(this.CONTROLLER + 'file-status.json');
  }

  GetFileNotificationsType(): Observable<IEnum[]> {
    return this.http.get<IEnum[]>(this.CONTROLLER + 'notificationTypes.json');
  }

  GetBusinessUnit(): Observable<IEnum[]> {
    return this.http.get<IEnum[]>(this.CONTROLLER + 'business-units.json');
  }

  GetPaymentStatus(): Observable<IEnum[]> {
    return this.http.get<IEnum[]>(this.CONTROLLER + 'payment-status.json');
  }

  GetCurrencies(): Observable<ICurrency[]> {
    return this.http.get<ICurrency[]>(this.CONTROLLER + 'currencies.json');
  }

  GetColors(): Observable<IEnum[]> {
    return this.http.get<IEnum[]>(this.CONTROLLER + 'colors-capacity.json');
  }

  GetobjectsToExcludeOnJson(): Observable<IEnum[]> {
    return this.http.get<IEnum[]>(this.CONTROLLER + 'exclude-objects-on-json.json');
  }


}


