import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CompanyCurrency, CompanyInitialData, ICompany, ICompanyFilter} from '../interfaces/companys';
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {Observable} from "rxjs";
import {CommonService} from "./common.service";
import {IUserByCompany} from "../interfaces/user";
import {IPermissionByRole} from "../interfaces/permissionByRole";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private readonly CONTROLLER = 'api/Company'

  constructor(private http: HttpClient,
              private commonService: CommonService,
              ) {
  }

  Get<T>(): Observable<ICLResponse<ICompany[]>> {
    return this.http.get<ICLResponse<ICompany[]>>(`${this.CONTROLLER}`, {
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al obtener companias`})
    });
  };

  GetCompaniesByFilter(filter: ICompanyFilter): Observable<ICLResponse<ICompany[]>> {
    let params = this.commonService.GetParamsByObject<ICompanyFilter>(filter);
    return this.http.get<Structures.Interfaces.ICLResponse<ICompany[]>>(`${this.CONTROLLER}/GetCompaniesByFilter`, {params, headers: this.commonService.DefineDescriptionHeader({OnError: `Error al obtener companias filtradas`})});
  };

  GetActiveCompanies(): Observable<ICLResponse<ICompany[]>> {

    return this.http.get<Structures.Interfaces.ICLResponse<ICompany[]>>(`${this.CONTROLLER}/GetActiveCompanies`, { headers: this.commonService.DefineDescriptionHeader({OnError: `Error al obtener companias activas`})});
  };

  GetAssignedCompanies(): Observable<ICLResponse<IUserByCompany[]>> {
    return this.http.get<Structures.Interfaces.ICLResponse<IUserByCompany[]>>(`${this.CONTROLLER}/AssignedCompanies`, { headers: this.commonService.DefineDescriptionHeader({OnError: `Error al obtener companias activas`})});
  };


  GetCompanyInitialData(): Observable<ICLResponse<CompanyInitialData>> {
    return this.http.get<ICLResponse<CompanyInitialData>>(`${this.CONTROLLER}/GetCompanyInitialData`,{
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al obtener datos iniciales de companias`})
    } );
  };

  GetCompanyCurrency(): Observable<ICLResponse<CompanyCurrency>> {
    return this.http.get<ICLResponse<CompanyCurrency>>(`${this.CONTROLLER}/CompanyCurrency`,);
  };

  Post(CompanyObj: ICompany): Observable<ICLResponse<ICompany>> {
    return this.http.post<ICLResponse<ICompany>>(`${this.CONTROLLER}`, CompanyObj, {
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al crear compania`})
    });
  };

  Patch(CompanyObj: ICompany): Observable<ICLResponse<ICompany>> {
    return this.http.patch<ICLResponse<ICompany>>(`${this.CONTROLLER}`, CompanyObj,{
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al actualizar companias`})
    });
  };

  GetCompaniesByUser(userId: number): Observable<ICLResponse<IUserByCompany[]>>{
    const params: HttpParams = new HttpParams()
      .set('userId', userId);
    return this.http.get<ICLResponse<IUserByCompany[]>>(`${this.CONTROLLER}/CompaniesByUser`, {params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener asignaciones de usuario ${userId}`
      })});
  }

  PatchCompanyByUser(companyIds: number[], userId: number): Observable<ICLResponse<IPermissionByRole>> {
    const params = {
      companyIds: companyIds,
      userId: userId
    }
    return this.http.patch<ICLResponse<IPermissionByRole>>(`${this.CONTROLLER}/CompanyByUser`, params, {
      headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al actualizar asignaciones de usuerio ${userId}`
      })
    });
  };

}
