import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IUsersFilter, IUser, IUserDto} from "../interfaces/user";
import {IFilterUserByStage} from "../interfaces/stage";
import {CommonService} from "./common.service";
import {F} from "@angular/cdk/keycodes";
import {IUsersCreatedBy, IUsersCreatedByFilter} from "../interfaces/user-company";

/*
  Service responsible for handling user-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly CONTROLLER = 'api/Users'

  constructor(
    private http: HttpClient,
    private  commonService: CommonService
  ) {
  }

  Get() : Observable<ICLResponse<IUser[]>>{
    return this.http.get<ICLResponse<IUser[]>>(`${this.CONTROLLER}`,{headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener usuarios`
      })});
  };


  GetUserByStage(filter: IFilterUserByStage) : Observable<ICLResponse<IUser[]>>{
    const params = new HttpParams()
      .set('StageId', filter.StageId)
      .set('StageType', filter.StageType)
      .set('FileId', filter.FileId)

    return this.http.get<ICLResponse<IUser[]>>(`${this.CONTROLLER}/UserByStage`,{params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener usuarios por etapa`
      })});
  }

  GetUserByRequestType(type: string) : Observable<ICLResponse<IUser[]>>{
    return this.http.get<ICLResponse<IUser[]>>(`${this.CONTROLLER}/UserByRequestType/${type}`, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener usuario por tipo de peticion`
      })});
  }

  Post(user: IUser): Observable<ICLResponse<IUser>> {
    return this.http.post<ICLResponse<IUser>>(`${this.CONTROLLER}`, user, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al crear usuario`
      })});
  };

  Patch(user: IUser): Observable<ICLResponse<IUser>> {
    return this.http.patch<ICLResponse<IUser>>(`${this.CONTROLLER}`, user, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al actualizar usuario`
      })});
  };

  GetUsersByFilter(filter: IUsersFilter | null): Observable<ICLResponse<IUserDto[]>> {
    let params = new HttpParams();
    if(filter != null){
      params = this.commonService.GetParamsByObject(filter)
    }
    return this.http.get<Structures.Interfaces.ICLResponse<IUserDto[]>>(`${this.CONTROLLER}/GetUsersByFilter`, {params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener usuarios filtrados`
      })});
  };

  GetUserLogged(): Observable<ICLResponse<IUserDto>> {

    return this.http.get<Structures.Interfaces.ICLResponse<IUserDto>>(`${this.CONTROLLER}/GetUserLoggedData`);
  };

  GetUsersCreatedByFilter(filter: IUsersCreatedByFilter) : Observable<ICLResponse<IUsersCreatedBy[]>>{
    const params = new HttpParams()
      .set('CompanyId', filter.CompanyId)
      .set('IsActive', filter.IsActive)
      //.set('LoggedUser', filter.LoggedUser)
    return this.http.get<ICLResponse<IUsersCreatedBy[]>>(`${this.CONTROLLER}/GetUsersCreatedByFilter`,{params, headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener usuarios por etapa`
      })});
  }

}
