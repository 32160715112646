
<div mat-dialog-content>
    <div class="table-container mat-elevation-z0">
        <cl-table [Id]="tableId" [MappedColumns]="displayedColumns" [Records]="companiesList"
                  [PageSizeOptions]="pageSizeOptions" [ItemsPeerPage]="itemsPeerPage" [RecordsCount]="recordsCount"
                  [HasPaginator]="true" [ScrollHeight]="scrollHeight" [Buttons]="Buttons" [ShouldPaginateRequest]="false">
        </cl-table>
    </div>

  <div fxLayoutAlign="end" class="mt-3">
    <button type="button" mat-flat-button color="primary" (click)="Logout()">
      Cerrar Sesión
    </button>
  </div>
</div>
