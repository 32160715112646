import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRole } from 'src/app/interfaces/role';
import { Structures } from '@clavisco/core';
import {Observable} from "rxjs";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {CommonService} from "./common.service";
import {IPermissionByRole} from "../interfaces/permissionByRole";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly CONTROLLER = 'api/Role'

  constructor(private http: HttpClient, private commonService: CommonService) { }

  Get():Observable<ICLResponse<IRole[]>>{
    return this.http.get<ICLResponse<IRole[]>>(`${this.CONTROLLER}`, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al obtener roles`
      })});
  };

  Post(_role: IRole):Observable<ICLResponse<IRole>>{
    return this.http.post<ICLResponse<IRole>>(`${this.CONTROLLER}`,_role, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al crear role`
      })});
  };

  Patch(_role: IRole):Observable<ICLResponse<IRole>>{
    return this.http.patch<ICLResponse<IRole>>(`${this.CONTROLLER}`,_role, {headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al actualizar role`
      })});
  };

  PatchPermissionsByRole(permissionIds: string, roleId: number): Observable<ICLResponse<IPermissionByRole>> {
    let params: { PermissionsId: string, RoleId: number } = {
      PermissionsId: permissionIds,
      RoleId: roleId
    }
    return this.http.patch<ICLResponse<IPermissionByRole>>(`${this.CONTROLLER}/PermissionByRole`, params, {
      headers: this.commonService.DefineDescriptionHeader({
        OnError: `Error al actualizar permisos del role ${roleId}`
      })
    });
  };
}
