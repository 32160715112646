import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private readonly CONTROLLER = 'api/Setting';

  constructor(private http: HttpClient) {}

  getMultiWindowConfig() {
    return this.http.get<ICLResponse<number>>(`${this.CONTROLLER}/MultiWindowConfig`);
  }
}
