
<cl-menu *ngIf="currentUser && ShowMenu"
         [Id]="MenuId"
         [Logo]="LogoSrc"
         [Title]="Title"
         [User]="currentUser.Email"
         [Nodes]="MenuNodes">
  <div class="app-container">
    <mat-toobar>
      <div class="header-container" style="background-color: white;">
        <div class="title-container">
          <button class="ml-1 mb-1 mt-1" mat-icon-button (click)="ToggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
          <div class="ml-1 page-title">
            <h3>{{CurrentPageTitle}}</h3>
          </div>
        </div>

        <div *ngIf="currentUser">
          <header class="navbar">
            <div class="vertical-divider">
              <button  matTooltip="Seleccionar compañía" mat-stroked-button color="secondary" (click)="OpenModalSelectCompany()" class="mr-1">
                <strong>Compañia: </strong>
                {{ companyInitialData?.CompanyName  ?? ''}}

              </button>

            </div>
            <div class="vertical-divider">
              <button [matTooltip]="'Moneda secundaria: ' + companyCurrency?.SysCurrencyName" mat-stroked-button color="secondary" class="mr-1">
              <strong>Moneda: </strong>
                 {{ companyCurrency?.MainCurrencyName  ?? ''}}
              
              </button>
              
            </div>
            <div class="vertical-divider">
              <span class="mr-1 ">
              <strong>Base de Datos: </strong>
                {{ companyInitialData?.DatabaseCode}}
            </span>
            </div>
            <div class="vertical-divider">
              <button  matTooltip="Refrescar" mat-stroked-button color="secondary"  (click)="GetCompanyInitialData()"  class="mr-1">
               
              <strong>Tipo de cambio: </strong>
                {{ companyInitialData?.ExchangeRate?.Rate ?? ''}}
               
            </button>
            </div>
            <div class="vertical-divider">
              <img  class="mr-1 " [src]="profile" alt="Foto perfil" class="Avatar mr-1">
              <span class="mr-2"><strong>Usuario:</strong> {{currentUser.Email}}</span>
            </div>
            <div class="notif-container">
              <cl-notification-center></cl-notification-center>
            </div>

          </header>
        </div>
      </div>
    </mat-toobar>
    <router-outlet name="primary"></router-outlet>
  </div>
</cl-menu>


<div *ngIf="currentUser && !ShowMenu">
  <router-outlet name="primary"></router-outlet>
</div>
