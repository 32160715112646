import {Component, OnInit} from "@angular/core";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  LoginId: string = 'main-login';
  ShouldResolve: boolean = true;
  ApiUrl: string = `${environment.ApiUrl}api/`;
  PathToRedirect: string ="Home";
  SesionName: string ="CurrentSession";
  LogoPath: string ="../../../assets/img/logo-login.png";
  DotNetApiType: 'CORE' = 'CORE';
  useReCaptcha: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }


}
