import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMenuNode } from '@clavisco/menu';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { IMenuItem } from 'src/app/interfaces/menu-node';
import {F} from "@angular/cdk/keycodes";

@Injectable({
  providedIn: 'root'
})


export class AppService {
  public reloadPayment: BehaviorSubject<boolean>;
  public reloadData: BehaviorSubject<boolean>;
  public changeCompany: BehaviorSubject<boolean>;
  public reloadComp: BehaviorSubject<boolean>;

  userAvatar: Subject<string>;

  constructor(public http: HttpClient) {
    this.reloadPayment = new BehaviorSubject<boolean>(false);
    this.reloadData = new BehaviorSubject<boolean>(false);
    this.changeCompany = new BehaviorSubject<boolean>(false);
    this.reloadComp = new BehaviorSubject<boolean>(false);
    this.userAvatar = new Subject<string>();
  };


}
