import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Injectable} from "@angular/core";
import {Repository, Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IMenuItem} from "../interfaces/menu-node";
import {CommonService} from "./common.service";

/*
  Service responsible for handling menu-related operations
 */
@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public MenuItem: Subject<IMenuItem[]>;
  Menu: IMenuItem[] = Repository.Behavior.GetStorageObject<IMenuItem[]>("KeyMenu") as IMenuItem[];
  public CurrentPageTitle : BehaviorSubject<string>;
  private readonly CONTROLLER = 'api/Menu'
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
    this.MenuItem = new Subject<IMenuItem[]>()
    this.CurrentPageTitle = new BehaviorSubject<string>('');
  }

  Get() : Observable<ICLResponse<IMenuItem[]>>{
    return this.http.get<ICLResponse<IMenuItem[]>>(`${this.CONTROLLER}`, {
      headers: this.commonService.DefineDescriptionHeader({OnError: `Error al obtener menu`})
    });
  };

  SetCurrentPageTitle(path:string): void {

    path = path.startsWith('/') ? path.substring(1) : path;
    this.CurrentPageTitle.next(this.GetCurrentViewName(path))
  };

  GetCurrentViewName(path: string) {
    return this.FindDescriptionRecursively(this.GetLocalMenu(), path);
  };

  FindDescriptionRecursively(menu: IMenuItem[], path: string): string {
    if(menu != null){
      for (const node of menu) {
        if ( node.Route != '' && path.startsWith(node.Route)) {
          return node.Description;
        }
        if (node.Nodes && node.Nodes.length > 0) {
          const description = this.FindDescriptionRecursively(node.Nodes, path);
          if (description) {
            return description;
          }
        }
      }
    }
    return "";
  }

  FindNodeByRoute(menu: IMenuItem[], path: string): IMenuItem | null {

    for (const node of menu) {
      let currentPath: string = path;
      if(!node.Visible){
        const lastSlashIndex: number = currentPath.lastIndexOf('/');
        currentPath = currentPath.slice(0, lastSlashIndex);
      }
      if (node.Route === currentPath && node.Key != 'Admin') {
        return node;
      }
      if (node.Nodes && node.Nodes.length > 0) {
        const foundNode: IMenuItem | null = this.FindNodeByRoute(node.Nodes, currentPath);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }

  GetLocalMenu(): IMenuItem[] {
    return Repository.Behavior.GetStorageObject<IMenuItem[]>('KeyMenu') as IMenuItem[];
  }

}
